import React from 'react';
import GetMusicByCategory from './GetMusicByCategory';
import RecentlyAddedCarousel from './RecentlyAddedCarousel';
import './style.css';
import MusicSearch from './MusicSearch';
import MostPlayed from './MostPlayed';
import { Helmet } from 'react-helmet';

function Home() {
  return (
    <div> 
      <Helmet>
      <meta
      name="description"
      content="Formerly known as Tarana Portal. A hub for Islamic songs, tarane, naat, hamd and many more. Find lyrics of favourite tarane. An Initiative of SIO Hyderabad"    />
            </Helmet>
        <RecentlyAddedCarousel />
        <MostPlayed />
             {/* <GetMusicByCategory category="Arabic Nasheed" /> */}
             {/* <MusicSearch /> */}
    </div>
  )
}

export default Home